<template>
  <common-warpper title="联系我们" en="CONTACT Us" :imgSrc="banner">
    <div class="contactUs">
      <div class="location">
        <baidu-map
          class="bm-view"
          scroll-wheel-zoom
          :center="{ lng: 108.39327, lat: 22.76584 }"
          :zoom="18"
          ak="cCQAO2D1N7ABtOra2H5TtyjG40ZwKqKG"
        >
          <bm-info-window
            :width="240"
            :position="{ lng: 108.39327, lat: 22.76584 }"
            title="<h4>广西云联产业发展有限公司</h4>"
            :show="infoWindow.show"
            @close="infoWindowClose"
            @open="infoWindowOpen"
          >
            <p
              style="color: #333; font-size: 14px; margin-top: 5px"
              v-text="infoWindow.contents"
            ></p>
            <!-- <button @click="clear">Clear</button> -->
          </bm-info-window>
        </baidu-map>
        <!-- <img src="@/assets/images/contactUs/address.jpg" /> -->
      </div>
      <ul class="info">
        <li>
          <div class="title">地址</div>
          <p>{{ pageData.platFormAddress }}</p>
        </li>
        <li>
          <div class="title">邮箱</div>
          <p>{{ pageData.platFormMail }}</p>
        </li>
        <li>
          <div class="title">客服</div>
          <p><span>电话：</span>{{ pageData.platFormMobile }}</p>
          <p><span>时间：</span>08:00-24:00</p>
        </li>
      </ul>
    </div>
  </common-warpper>
</template>
<script>
import CommonWarpper from "@/components/CommonWarpper.vue";
import banner from "@/assets/images/banner/contactUs.jpg";
import { getPlatform } from "@/api/index";

export default {
  name: "ContactUs",
  components: {
    CommonWarpper,
  },
  data() {
    return {
      banner,
      pageData: {},
      infoWindow: {
        show: true,
        contents: "凯旋路3号宁泰新港湾大厦11楼1116室",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getPlatform().then((res) => {
        this.pageData = res.data;
      });
    },
    infoWindowClose(e) {
      this.infoWindow.show = false;
    },
    infoWindowOpen(e) {
      this.infoWindow.show = true;
    },
    clear() {
      this.infoWindow.contents = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
.contactUs {
  .location {
    img {
      width: 100%;
    }
    .bm-view {
      height: 355px;
    }
  }
  .info {
    display: flex;
    padding-top: 60px;
    padding-bottom: 40px;
    li {
      flex: 1;
      padding: 0 70px;
      background-position: 40px 2px;
      background-repeat: no-repeat;
      background-size: 19px;
      &:nth-child(1) {
        background-position: 40px 5px;
        background-image: url(../assets/images/contactUs/icon1.png);
      }
      &:nth-child(2) {
        background-image: url(../assets/images/contactUs/icon2.png);
      }
      &:nth-child(3) {
        background-image: url(../assets/images/contactUs/icon3.png);
      }
      .title {
        font-size: 18px;
        color: #000;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        color: #983436;
        line-height: 1.6;
        padding-left: 3px;
        span {
          color: #777777;
        }
      }
    }
  }
}
</style>
