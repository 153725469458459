<template>
  <div class="common-page-warpper">
    <img class="banner" :src="imgSrc" :style="{ height: imgHeight }" />
    <div class="common-warpper">
      <div class="warpper-content page-width-warpper">
        <Title :isWhite="isWhite" :title="title" :en="en" />
        <div style="padding-top: 30px"><slot></slot></div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/views/home/components/Title.vue";
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    en: {
      type: String,
      default: "",
    },
    imgSrc: {
      type: String,
      default: require("@/assets/images/home/banner2.jpg"),
    },
    imgHeight: {
      type: String,
      default: "300px",
    },
  },
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss"; // global css
.common-page-warpper {
  .banner {
    width: 100%;
    object-fit: cover;
  }
  .common-warpper {
    background: #f0f6fb;
    padding-bottom: 60px;
    box-sizing: border-box;
    min-height: calc(100vh - 521px);
    .warpper-content {
      position: relative;
      z-index: 11;
      border-radius: 2px;
      background: #fff;
      box-shadow: $box-shadow;
      margin-top: -50px;
      padding: 60px 90px;
    }
  }
}
</style>
